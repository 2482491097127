.access-info{
    .child{
        height: 0;
        overflow: hidden;
        &.childActive{
            height: auto;
        }
    }
    .childLabel{
        position: absolute;
        left: 20px;
    }
}
.user-acces-info{
    .modulename{
        min-width: 100px;
        display: inline-block;
        font-weight: 500;
    }
    .user-access-arrow{
        &.activeindex{
            transform: rotate(180deg);
        }
    }
}