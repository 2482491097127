html{
    font-size: 62.5%;
}
html,body{
	height: 100%;
}
@font-face {
	font-family: 'SFProText-Regular';
	src: 	url('./../../fonts/SFProText-Regular.eot') format('embedded-opentype'),  
			url('./../../fonts/SFProText-Regular.woff') format('woff'), 
			url('./../../fonts/SFProText-Regular.ttf')  format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'SFProDisplay-Bold';
	src: 	url('./../../fonts/SFProDisplay-Bold.eot') format('embedded-opentype'),  
			url('./../../fonts/SFProDisplay-Bold.woff') format('woff'), 
			url('./../../fonts/SFProDisplay-Bold.ttf')  format('truetype'); 
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'SFProText-Semibold';
	src: 	url('./../../fonts/SFProText-Semibold.eot') format('embedded-opentype'),  
			url('./../../fonts/SFProText-Semibold.woff') format('woff'), 
			url('./../../fonts/SFProText-Semibold.ttf')  format('truetype');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'SFProText-Medium';
	src: 	url('./../../fonts/SFProText-Medium.eot') format('embedded-opentype'),  
			url('./../../fonts/SFProText-Medium.woff') format('woff'), 
			url('./../../fonts/SFProText-Medium.ttf')  format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'SFProText-Bold';
	src: 	url('./../../fonts/SFProText-Bold.eot?#iefix') format('embedded-opentype'),  
			url('./../../fonts/SFProText-Bold.woff') format('woff'), 
			url('./../../fonts/SFProText-Bold.ttf')  format('truetype'), 
			url('./../../fonts/SFProText-Bold.svg#SFProText-Bold') format('svg');
			
	font-weight: normal;
	font-style: normal;
}
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,500&display=swap');
body{	
    font-size: 1.5rem;
	font-family: $body-font;
	font-weight: normal;
	// letter-spacing: 0.5px;
	color: $dark-color;
	background-color: #EDEFF1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: 'Roboto', sans-serif;
	p{
		font-size: 15px; line-height: 22px;
		font-weight: 400;
	}
}
h1, .jumbo-heading_2{
	font-size: 5rem;
	line-height: 7rem;
	font-weight: 500;
}
h2 {
	font-size: 3.0rem;
	line-height: 3.0rem;
	font-weight: 500;
}
h3 {
	font-size: 2.5rem;
	line-height: 2.5rem;
	font-weight: 700;
}
h4 {
	font-size: 2.0rem;
	line-height: 2.0rem;
	font-weight: 500;
}
h5{
	font-size: 1.5rem;
	line-height: 1.5rem;
	font-weight: 500;
}
h6{
	font-size: 1.3rem;
	line-height: 1.3rem;
	font-weight: 500;
}
.jumbo-heading {
	font-size: 6rem;
	line-height: 6rem;
}
h1,h2,h3,h4,h5,h6, .jumbo-heading, .jumbo-heading_2{
	font-family: $title-font;
	color: $title-color ;
	letter-spacing: normal;
}
a{
	color: $primary-color;
	&:hover{
		text-decoration: none;
	}
}
strong { font-weight: 700; }
.mr-3 	{margin-right:1rem !important}
.mr-4 	{margin-right:1.5rem !important}
.mb-3 	{margin-bottom:1rem !important}
.mb-4 	{margin-bottom:1.5rem !important}
.border{
	&--right{
		border-right: solid 1px #EBEBEB	;
	}
	&--left{
		border-left: solid 1px #EBEBEB	;
		&__blue{
            border-left:1px solid #157CF7;
        }
	}
}
.large-heading {
	font-size: 4rem;
	line-height: 4.5rem;
	font-weight: 700;
}
.color-orange { color: #F99F1B; }
.text-right { text-align: right !important;}