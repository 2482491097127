.container-fluid {
  padding: 3rem;
}
.app {
  height: 100vh;
  overflow: hidden;
}
.scrollbar-container {
  position: relative;
  height: 100%;
}
.main-content {
  // margin-left: 23.8rem;
  //   padding: 2.6rem;
  transition: all 0.2s ease-out;
  height: calc(100% - 6rem);
  display: flex;
  flex-flow: column;
  align-items: stretch;
  overflow: auto;
  // display: flex;
  // flex-direction: column;
}
.wrapper {
  // display: flex;
  // flex-wrap: wrap;
  // flex-direction: column;
  // min-height: calc(100vh - 230px);
  // overflow: hidden;
  // overflow-y: scroll;
}
.invoice_no {
  color: #157cf7;
  font-weight: 600;
}
.status_msg {
  &:hover {
    svg {
      // color: $link-color;
    }
  }
}
.blacklised_email {
  position: relative;
  left: -15px;
  margin-left: -20px;
}
.invite_header{
  background: linear-gradient(to bottom, #343434 30%, #434343 100%);
}
@media (min-width: 1200px) and (max-width: 1400px) {
  html {
    font-size: 47%;
  }
}

.react-select-container,
.dropdown,
.custome-select-container,
.search-dropdown__menu {
  /* width */
  ::-webkit-scrollbar {
    width: 8px;
    border-radius: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #b9b9b9;
    border-radius: 8px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #b9b9b9;
  }
}
.domain__lists .domain__list:first-child {
  div {
    padding-top: 0 !important;
  }
}
.invitation_form{
  .form-group-with-error{
    // max-width: 396px;
    .addonBtn{
      position: absolute;
      right: -35px;
    }
  }
}
.popover{
 border: none!important;

  .popover-body{
    background: #E7E7E7;
    border-radius: 3px;
    padding: 1rem;
  }
  
  h4{
    font-size: 10px;
    color: #000000;
    line-height: normal;
    font-weight: 600;
    margin-bottom: 10px;
  }
  p{
    font-size: 10px;
    color: #000000;
    line-height: normal;
    margin-bottom: 0;
  }
}