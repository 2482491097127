@mixin gradient_dark{
	background: #343434; /* Old browsers */
	background: -moz-linear-gradient(top, #343434 30%, #434343 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #343434 30%,#434343 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #343434 30%,#434343 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#343434', endColorstr='#434343',GradientType=0 ); /* IE6-9 */
}
@mixin gradient_sidebar{
	background: #343434; /* Old browsers */
	background: -moz-linear-gradient(top, #343434 30%, #434343 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #343434 30%,#434343 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #343434 30%,#434343 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#343434', endColorstr='#434343',GradientType=0 ); /* IE6-9 */
}
@mixin gradient_secondry{
	background: #193015;
	background: -moz-linear-gradient(45deg, #193015 30%, #6E9567 70%);
	background: -webkit-linear-gradient(45deg, #193015 30%, #6E9567 70%);
	background: -o-linear-gradient(45deg, #193015 30%, #6E9567 70%);
	background: -ms-linear-gradient(45deg, #193015 30%, #6E9567 70%);
	background: linear-gradient(45deg, #193015 30%, #6E9567 70%);
}
@mixin btn{
	background: lighten($brand-color,10%);
	background: -moz-linear-gradient(80deg, lighten($brand-color,10%) 30%, $brand-color 70%);
	background: -webkit-linear-gradient(80deg, lighten($brand-color,10%) 30%, $brand-color 70%);
	background: -o-linear-gradient(80deg, lighten($brand-color,10%) 30%, $brand-color 70%);
	background: -ms-linear-gradient(80deg, lighten($brand-color,10%) 30%, $brand-color 70%);
	background: linear-gradient(80deg, lighten($brand-color,10%) 30%, $brand-color 70%);
}
@mixin btnhover{
	background: $brand-color;
	background: -moz-linear-gradient(80deg, $brand-color 30%, lighten($brand-color,10%) 70%);
	background: -webkit-linear-gradient(80deg, $brand-color 30%, lighten($brand-color,10%) 70%);
	background: -o-linear-gradient(80deg, $brand-color 30%, lighten($brand-color,10%) 70%);
	background: -ms-linear-gradient(80deg, $brand-color 30%, lighten($brand-color,10%) 70%);
	background: linear-gradient(80deg, $brand-color 30%, lighten($brand-color,10%) 70%);
}
@mixin btn-default-gradient{
	background: #272727;
	background: -moz-linear-gradient(180deg, #4A4A4A 0%, #272727 100%);
	background: -webkit-linear-gradient(180deg, #4A4A4A 0%, #272727 100%);
	background: -o-linear-gradient(180deg, #4A4A4A 0%, #272727 100%);
	background: -ms-linear-gradient(180deg, #4A4A4A 0%, #272727 100%);
	background: linear-gradient(180deg, #4A4A4A 0%, #272727 100%);
}

@mixin default-error-gradient{
	background: #D54A53;
	background: -moz-linear-gradient(135deg, #D0393E 0%, #D54A53 100%);
	background: -webkit-linear-gradient(135deg, #D0393E 0%, #D54A53 100%);
	background: -o-linear-gradient(135deg, #D0393E 0%, #D54A53 100%);
	background: -ms-linear-gradient(135deg, #D0393E 0%, #D54A53 100%);
	background: linear-gradient(135deg, #D0393E 0%, #D54A53 100%);
}