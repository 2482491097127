@import "../../scss/utils/variables";


.help{

    &__main{
        
        &__screen{
            background-color: #FFFFFF;
            padding: 40px;
        }
    }
    &__page{

    }
    &__inner{
        max-width: 980px;
        margin: 0 auto;

        &__search{
            max-width: 545px;
            margin: 0 auto 40px;
            .react-select-container{
                .react-select__control{
                    display: none;
                }
            }

            h4{
                text-align: center;
                font-size: 2.4rem;
                line-height: 2.9rem;
                font-weight: bold;
                margin: 0 0 25px 0;
            }
            .search-box{
                position: relative;

                input[type="search"]::-webkit-search-decoration,
                input[type="search"]::-webkit-search-cancel-button,
                input[type="search"]::-webkit-search-results-button,
                input[type="search"]::-webkit-search-results-decoration {
                    -webkit-appearance:none;
                }

                .form-control{
                    border-color: #D3D3D3;
                    border-radius: 2px;
                    height: 40px;
                    padding-right: 40px;
                    
                }

                .btn{
                    height: 24px;
                    width: 24px;
                    display: flex;
                    align-items:center;
                    justify-content: center;
                    position: absolute;
                    top: 8px;
                    right: 12px;

                    

                    path{
                        fill: #357BCF;
                    }
                }
            }
        }
        &__category{
            
            padding-bottom: 60px;

            h4{
                text-align: center;
                font-size: 2.4rem;
                line-height: 2.2rem;
                font-weight: bold;
                margin: 0 0 25px 0;
                position: relative;

                .btn{
                    height: 26px;
                    width: 26px;
                    border-radius:5px;
                    padding: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    left: 0;
                    top: -2px;
                }
            }
            .category-row{

                display: flex;
                flex-wrap: wrap;

                .category-item{
                    flex:0 0 270px;
                    max-width: 270px;
                    border-radius: 1px;
                    background-color: #F8F8F8;
                    padding: 28px 20px 26px 20px;
                    transition: all 0.4s ease-in-out ;
                    margin-bottom: 40px;
                    margin:20px;
                    cursor: pointer;
                    // &:not(:nth-child(3n)){
                    //     margin-right: 45px;
                    // }

                    &:hover{
                        background-color: #357BCF;
                        color:#fff;
                        h5{
                            color:#fff;
                        }
                        .category-item-icon{
                            path{
                                fill:#fff;
                            }
                        }
                    }

                    .category-item-icon{
                        height: 64px;
                        width: 64px;
                        margin: 0 auto 14px;
                        display: flex;
                        align-items:center;
                        justify-content: center;

                        path{
                            transition: all 0.4s ease-in-out ;

                        }
                    }
                    h5{
                        text-align: center;
                        font-size: 1.5rem;
                        line-height: 2.2rem;
                        font-weight: bold;
                        margin: 0 0 13px 0;
                        transition: all 0.4s ease-in-out ;
                    }
                    p{
                        text-align: center;
                        font-size: 1.5rem;
                        line-height: 2.2rem;
                        margin: 0;
                    }
                }
            }
            .category-details{
                border-radius: 1px;
                background-color: #F8F8F8;
                display: flex;
                align-items:stretch;
                overflow: hidden;

                .category-details-icon{
                    flex:0 0 271px;
                    max-width: 271px;
                    background-color: #343434;
                    display: flex;
                    align-items:center;
                    justify-content: center;
                    flex-direction: column;

                    &>div{
                        height: 64px;
                        width: 64px;
                        margin: 0 auto 14px;
                        display: flex;
                        align-items:center;
                        justify-content: center;
                    }
                    h5{
                        text-align: center;
                        font-size: 1.5rem;
                        line-height: 2.2rem;
                        font-weight: bold;
                        margin: 0 ;
                        color: #FFF;
                    }
                }
                .category-details-content{
                    flex:0 0 calc(100% - 271px);
                    max-width: calc(100% - 271px);
                    padding: 46px 41px 45px 78px;

                    ul{
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;

                        li{
                            max-width: 230px;
                            flex:0 0 230px;
                            line-height: 4rem;

                            a{
                                color: #343434;
                                font-size: 1.5rem;
                            }
                        }
                    }
                }
            }
        }
        &__questions{

            padding-bottom: 60px;

            h4{
                text-align: center;
                font-size: 2.5rem;
                line-height: 2.9rem;
                font-weight: bold;
                // margin: 0 0 40px 0;
            }
            .question-row{
                
                .card{
                    margin: 0 0 40px 0;
                    border-radius: 5px;
                    background-color: #F8F8F8;
                    border:0px;

                    .card-header{
                        background-color:transparent;
                        border:0px;
                        padding: 39px 60px 39px 30px;
                        position: relative;
                        cursor: pointer;
                        transition: padding 0.2s ease-in-out;

                        &.question-open{
                            padding-bottom: 22px;
                            .icon-box{
                                transform: rotate(180deg);
                            }
                        }
                        
                        
                        h5{
                            margin: 0px;
                            font-size: 1.5rem;
                            line-height: 2.2rem;
                            font-weight: bold;
                        }
                        .icon-box{
                            height: 18px;
                            width: 18px;
                            position: absolute;
                            right: 30px;
                            top:42px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            transition: all 0.2s ease-in-out;

                            path{
                                fill:#357BCF;
                            }
                        }
                    }
                    .card-body{
                        padding:0 28px 30px 30px;
                    }
                }
            }
        }
        &__contact-us{

            background-color: #F8F8F8;
            padding: 35px;

            h4{
                text-align: center;
                font-size: 2.5rem;
                line-height: 2.9rem;
                font-weight: bold;
                margin: 0 0 40px 0;
            }
            .btn{
                margin: 0 auto;
                width: 205px;
                height: 50px;
                display: block;
            }
        }
    }
    
}

@media screen and (max-width:1299px){
    .help__inner{
        max-width: 100%;

        &__category{
            .category-row{
                .category-item{

                    &:nth-child(3n){
                        margin-right: 0px;
                    }
                    flex: 0 0 calc(100%/3 - 30px);
                    max-width: calc(100%/3 - 30px);
                }
            }
            .category-details{
                .category-details-content{
                    padding: 45px 40px;
                    
                    ul{
                        li{
                            max-width: 50%;
                            flex: 0 0 50%;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width:1024px){
    .help__inner{
        max-width: 100%;

        &__category{
            .category-row{
                justify-content: space-between;
                .category-item{
                    margin-right:0 !important;
                    flex: 0 0 calc(100%/2 - 20px);
                    max-width: calc(100%/2 - 20px);
                }
            }
            .category-details{
                .category-details-icon{
                    flex: 0 0 180px;
                    max-width: 180px;
                }
                .category-details-content{
                    flex: 0 0 calc(100% - 180px);
                    max-width: calc(100% - 180px);
                    padding: 20px 20px;
                    
                    ul{
                        li{
                            max-width: 100%;
                            flex: 0 0 100%;
                        }
                    }
                }
            }
        }
    }
}