.get_code_modal{
    &.modal-dialog{
        max-width: 722px;
        background: #FBFBFB;
    }
    
    h2{
        font-weight: 700;
    }
    p{
        font-size: 18px;
    }
    .modal-content{
        padding: 6rem 14rem;

    }
    .form-control{
        height: 263px;
        background: #FFFFFF;
        border: 1px solid #BDBDBD;
        box-sizing: border-box;
        border-radius: 5px;
    }
}
.contact_section{
    .col-lg-4 {
        &:last-child {
            border-right: 0;
        }
    }
}
.tos__modal{
    .tos__content{
        max-height: 80vh;
        // overflow-y: auto;
        padding-right: 15px;
    }
}

.react-pdf__Page__svg{
    margin: 0 auto;
    margin-bottom: 15px;
    border: 1px solid #000;
}

.react-pdf__Document{
    overflow: hidden;
}

.react-select--is-disabled .react-select__control 	{ opacity: 0.6; background-color: #e9ecef; }
.send-email:hover {
    background-color: #357BCF !important;
    color: #fff;
}