.dashboard-sec {
    margin: 5rem 0;
    .dashboard-list{ display: flex; flex-wrap: wrap;
        li{ width: calc(100% / 3 - 27px); margin-right: 40px; margin-bottom: 40px; background-color: #fff; flex-grow: 1;
            .dashboard-header{ padding: 2rem; background-color: #f8f8f8; color: #343434; text-align: center; min-height: 140px;
                svg path { fill : #343434; }
                h4 { font-weight: 700;font-size: 2.5rem;}
                p { word-wrap: break-word; margin-bottom: 0; }
            }
            .dashboard-body-sec { padding: 0px 3rem; text-align: center; background-color: $white-color;
                .dashboard-body { padding: 5rem 0; border-bottom: 1px solid #EBEBEB;
                h2.large-heading{
                    font-size: 5.6rem;
                    margin-bottom: 2rem;
                }
                }
            }
            .dashboard-body-footer { padding: 2.5rem 0;
                .outline-primary{ 
                    path { fill: #357BCF;}
                    &:hover svg path { fill : #fff; }
                 }
            }
            &:last-child        { margin-right: 0;}
            &:nth-child(3n){ margin-right: 0; }
        }
    }
    .active-promocode-sec { background-color: #f8f8f8; border-radius: 10px;
        .active-promocode-header { padding: 3.5rem 2rem; text-align: center;}
        .promocode-table {
            th { background-color: $white-color; padding: 1.5rem 4.5rem; }
            td { padding: 1rem 4.5rem; }
        }
    }
 }
 .webstream-search { min-width: 300px; }
 .link-black { color: #343434; text-decoration: none;
    &:hover { color: #357BCF; }
 }
.mw-80{
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
}

// Overview
.react-tel-input .form-control{
    font-size: 1.4rem;
    color: #343434;
    border-radius: 2px;
    border: 1px solid #D3D3D3;
    height: 4.1rem;
    box-shadow: none;
    width: 100%;
}
.tier-header   {  text-transform: capitalize; padding: 20px 0px; padding-right: 30px; background-color: #343434; color: #fff; text-align: center; position: relative;
    h4 { color: #fff; margin-bottom: 0; }
    input { position: absolute; top: 50%; transform: translateY(-50%); right: 25px; cursor: pointer; }
}
.tier-desc { padding: 0px 20px; border: 1px solid  #D6D6D6;;
    ul {
        li { padding: 20px 0px; border-bottom: 1px solid  #D6D6D6;
            &:last-child{
                border: none;
            }
            p { margin-bottom: 10px; font-size: 15px; list-style: 22px; }
            div { padding: 5px 0px; display: flex;-webkit-display:flex; flex-wrap: wrap; justify-content: space-between; align-items: center;
                span { color: #757575; font-size: 15px; list-style: 22px;  display: inline-block; }
                h6 { margin-bottom: 0;}
            }
        }
    }
}
.showmore-tier     { margin-top: 20px; display: inline-block; color: #157cf7;
    &:hover { color: #157cf7; }
}
.affilate-payment-info {
    textarea { width: 100%;  resize: none; }
}

.profile-tabs  { background-color: #343434;
    .nav-link { padding: 14px 28px; color: #fff;border: none !important; cursor: pointer;
        &:hover {color: #fff; }
    }
    .nav-link.active { color: #343434;
        &:hover { color: #343434; }
    }
}
.mt-50                      { margin-top: 30px !important;}
.promo-label                { max-width:12.3%;}
.profile-list               { display: flex; flex-wrap: wrap; margin: 0px -20px; }
.profile-list li            { display: inline-block; margin: 0px 20px; text-align: center; display: flex; flex-wrap: wrap;  flex-direction: column; justify-content: flex-end; }
.profile-list li div        { padding: 20px;  display: flex; flex-wrap: wrap;  justify-content: center; align-items: center; width: 135px; height: 135px; border-radius: 5px; border: 1px solid #343434; margin: 0px auto; }
.bg-gray                    {  background: #343434; }
.profile-list li div.btn-group-outline-primary { border-color: #357BCF;}
.profile-img                { padding: 20px;  display: flex; flex-wrap: wrap;  justify-content: center; align-items: center; width: 135px; height: 135px; border-radius: 5px; border: 1px solid #343434; margin: 0px auto; }
// .bg-white                   { background: #fff !important;}
.bg-white.btn-outline-red::before { background: transparent;}
.auth-bg                        { background: #FF3F34; }
.auth-bg p                  { color: #fff;;}
.bg-white.btn-outline-red:hover,
.bg-white.btn-outline-red:focus { color: #FF3F34 !important; border-color: #FF3F34; }
a.btn-outline-primary        { padding: 1.5rem 4rem; width: 100%; max-width: 20.5rem;}
.webstream-table input[type='checkbox']{ z-index: 2;; }
.custom-checkbox .custom-control-label { font-family: "SFProText-Bold", sans-serif; }
.custom-control-inline.custom-checkbox .custom-control-input { margin: 0; z-index: 1; }
.invoice-input .mb-5             { margin-bottom: 5px !important;}
.invoice-input .mb-10             { margin-bottom: 10px !important;}
.danger-box                 { background-color: #FF3F34; color: #fff; border-radius: 5px; padding: 5px 10px; }
.country-select > div,
.country-select > div > div          { border-color: #D3D3D3 !important; }
// .country-select > div > div > div > div     { height: 42px;}

// Reports 

.custom-checkbox .custom-control-label.reports-checkbox { font-family: "SFProText-Regular", sans-serif !important;}

.affilate-report-left       { background-color: #FEF7ED; height: 100%; }
.thalasum-info li           { margin-bottom: 20px; display: flex; -webkit-display: flex;; flex-wrap: wrap; align-items: flex-start; }
.thalasum-info li img       { width: 20px;margin-right: 10px; }
.thalasum-info li span      { width: calc(100% - 30px); font-size: 14px; line-height: 20px;; }
.affilate-report-bg         { margin-bottom: 10px; background-color: #EDEFF1; padding: 10px; }
.affilate-report-sec        { background-color: #fff; }
.affilate-report-right p    { font-size: 14px; line-height: 22px; margin-bottom: 0;;}
.divider                    { border: 2px dashed #EDEFF1; width: 100%;; }
.affilate-commison-list     { margin-top: 20px; min-height: 800px;}
.affilate-commison-list li  { margin-bottom: 10px; padding: 5px 0px;display: flex; -webkit-display: flex;; flex-wrap: wrap;  justify-content: space-between; align-items: center; }
.affilate-commison-list li span { font-size: 14px; line-height: 20px; display: inline-block; }
.affilate-commison-list li .sr-no { margin-right: 10px;}
.affilate-commison-list li img { margin-right: 10px;}
.affilate-report-left,
.affilate-report-right       { display: flex; -webkit-display: flex;justify-content: space-between; flex-direction: column;}
.total-footer p              { font-size: 18px; line-height: 26px; }
.summary-list               { border-bottom: 1px solid #434343;}
.summary-list li             { margin-bottom: 10px; display: flex; -webkit-display: flex; flex-wrap: wrap; justify-content: space-between;}
.summary-list li span:first-of-type { width: 40%; }
.summary-list li span:last-of-type { width: 60%; }
.status-dropdown .react-select__control { padding: 5px 0px;}
.affilate-dark-report       { background-color: #2D2D2D; }
.affilate-dark-report .affilate-report-left { background-color: #1B1A1A;}
.affilate-dark-report .affilate-report-right { background-color: #2D2D2D;color: #fff; }
.affilate-dark-report .thalasum-info li span,
.affilate-dark-report .thalasum-info li span a,
.affilate-dark-report .affilate-report-right h3,
.affilate-dark-report .affilate-report-right h4,
.affilate-dark-report .thalasum-info h3 { color: #fff;}
.affilate-dark-report .divider  { border-width: 1px;}
.affilate-dark-report table     { color: #fff;}
.affilate-dark-report table th { font-size: 20px; line-height: 27px;}
.affilate-dark-report table th,
.affilate-dark-report table td        {color: #fff; border: none;}
.affilate-dark-report table th:first-child,
.affilate-dark-report table td:first-child { padding-left: 0 !important;white-space: normal;}
.affilate-dark-report table tr        { border: none !important;}
.gray-border                { border: 2px solid #e7e7e7; }
.border-none                { border: none; }

.paymentmodal               {    max-width: 70%; max-height: 90vh; 
    .modal-content          { background-color: #FBFBFB; overflow-y: auto; height: 100%; } 
    .modal-body             { padding:0; overflow-y: auto; height: 100%; }
}

// User Rights 

// .user-profile div        { padding: 20px;  display: flex; flex-wrap: wrap;  justify-content: center; align-items: center; width: 135px; height: 135px; border-radius: 5px; border: 1px solid #343434; margin: 0px auto; }
.bg-gray                    {  background: #343434; }
.user-profile div.btn-group-outline-primary { border-color: #357BCF;}
.profile-img                { padding: 20px;  display: flex; flex-wrap: wrap;  justify-content: center; align-items: center; width: 135px; height: 135px; border-radius: 5px; border: 1px solid #343434; margin: 0px auto; }
// .bg-white                   { background: #fff !important;}
.bg-white.btn-outline-red::before { background: transparent;}

.user-right-list li         { background-color: #fff; display: flex; flex-wrap: wrap; position: relative;
    .user-acces-info      { width: 40%; padding: 0px 15px;}
    .access-info          { width: 30%; padding: 0px 15px; }
}
.accordian-data {    display: flex; flex-wrap: wrap; width: 100%;
    .user-acces-info      { width: 40%; padding: 0px 15px;}
    .access-info          { width: 30%; padding: 0px 15px; }
}

.user-right-list li:nth-child(even) { background-color: #f8f8f8; }
.user-rights-label span     { margin-right: 3rem; }
.user-rights-label > div    { cursor: pointer; }
.user-submenu-open svg         { transform: rotate(180deg); }

.user-right-list .custom-checkbox .custom-control-input { top: 5px; }
.user-right-list .custom-checkbox input:checked + .custom-control-label:after { top: 11px;}
.cusome-file-upload {cursor: pointer; }
.cusome-file-upload label {cursor: pointer;}
@media (min-width:1200px) and (max-width:1450px){
    .webstream-search  { min-width: 230px;}
}

@media (min-width:768px) and (max-width:1191px){
    .dashboard-sec{
        .dashboard-list{
            li { width: calc(100% - 27px);}
        }
    }
    .access-info div { padding-right: 0 !important; padding-left: 0 !important;}
}
