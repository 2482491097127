*{
    margin: 0;
    padding: 0;
}
*, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
b, strong{
    font-family: $body-font;
    font-weight: 500;
}
ul{
    list-style: none;
    margin: 0;
    padding: 0;
}
.sidebartwo{
    margin-top: 10px;
    color: grey;
    list-style: none
}

.sidebartwo li {
    
    padding:10px 10px;
}
.sidebartwo li::before {content: "•"; color: grey;
    display: inline-block; width: 1em;
    margin-left: -0.7em
}

img {
    max-width: 100%;
}
a{
    text-decoration: none;
}
:focus {
    outline: none;
}
p{
    margin-bottom: 2rem;
}