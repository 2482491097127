@import "../utils/variables";
.btn {
   font-size: 1.5rem;
   font-family: $body-font;
   font-weight: 400;
   border-radius: 1.5px;
   padding: 1.4rem 1.5rem;
   border: none;
   position: relative;

   &.mh-40{
     min-height: 40px;
   }
   
   &:focus {
     outline: none !important;
     box-shadow: none !important;
   }
   &:active {
    outline: none !important;
     box-shadow: none !important;
  }
   .spinner-border{
    //  position: absolute;
    //  left: 10px;
   }
    &.btn-xl{
      max-width: 35rem;
      width: 100%;
    }
    &.btn-xxl{
      max-width: 38.8rem;
      width: 100%;
    }
    &.btn-lg{
      max-width: 26rem;
      width: 100%;
    }
    &.btn-md{
      max-width: 22rem;
      width: 100%;
    }
    &.btn-sm{
      max-width: 16.5rem;
      width: 100%;
    }
   &.disabled{
        background: #B5B5B5;
        border-color: #B5B5B5;
        color: #FFFFFF;
        opacity: 1;
   }
   &.verifyed {
      background: linear-gradient(135deg, #35A85A 0%, #4DA166 100%);
   }
   &.notverifyed{
    &:not(:disabled):not(.disabled),
    &:not(:disabled):not(.disabled):focus{
      background: linear-gradient(135deg, #D0393E 0%, #D54A53 100%);
      color: $white;
    }
     
   }
   &-default{
        @include  btn-default-gradient;
        border-color: $btn-default-border;
        color: $btn-default-color;
        &:hover{
            color: $btn-default-color;
        }
  }
  &-filter{
    font-size: 1.4rem;
    padding: 0.9rem 1rem;
    color: $main-font-color;
    min-width: 110px;
    border-radius: 2px;
    background-color: #f8f8f8;
    i{
      display: inline-block;
      
      transition: all .2s ease;
      transform: rotate(0deg);
    }
    &.active{
      i{
        transform: rotate(-180deg);
      }
    }
    }
  &-link{
    color: $white;
    font-weight: 500;
    line-height: 1;
    text-transform: none;
    &.primary{
      color: $primary-color!important;
      background-color: transparent;
      text-decoration: none;
      &:focus, &.focus,&:hover{
        color:  $primary-color;
      }
    }
    &:focus, &.focus,&:hover{
      // color: $white;
      text-decoration: none;
      box-shadow: none;
    }
    &.white{
      color: $white;
      &:focus, &.focus,&:hover{
        color:  $white;
      }
    }
  }
  &-primary{
    color: $btn-primary-color;
    &:not(:disabled):not(.disabled):active, 
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active:focus, 
    &:not(:disabled):not(.disabled).active:focus,
    &:not(:disabled):not(.disabled):focus{
      color: $btn-primary-color;
      background-color: $primary-color;
      outline: #fff solid 1px;
    }
    &:not(:disabled):not(.disabled){
      color: $btn-primary-color;
      background-color: $primary-color;
      box-shadow:none;
      &:hover{
        color: $btn-primary-color;
        background-color: $primary-color;
      }
    }
  }
  &-red{
    color: $btn-default-color;
    &:not(:disabled):not(.disabled):active, 
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active:focus, 
    &:not(:disabled):not(.disabled).active:focus,
    &:not(:disabled):not(.disabled):focus{
      color: $btn-default-color;
      background-color:$red;
      // outline: #fff solid 2px;
    }
    &:not(:disabled):not(.disabled){
      color: $btn-default-color;
      background-color: $red;
      box-shadow:none;
      &:hover{
        color: $btn-default-color;
        background-color: $red;
      }
    }
  }
  &-secondry{
    color: $btn-primary-color;
    &:not(:disabled):not(.disabled):active, 
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active:focus, 
    &:not(:disabled):not(.disabled).active:focus,
    &:not(:disabled):not(.disabled):focus{
      color: $btn-primary-color;
      background-color: $primary-color;
      outline: #fff solid 1px;
    }
    &:not(:disabled):not(.disabled){
      color: $btn-primary-color;
      background-color: $primary-color;
      box-shadow:none;
      &:hover{
        color: $btn-primary-color;
        background-color: $primary-color;
      }
    }
  }
  &-white{
    color: $main-font-color;
    &:not(:disabled):not(.disabled):active, 
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active:focus, 
    &:not(:disabled):not(.disabled).active:focus,
    &:not(:disabled):not(.disabled):focus{
      color: $main-font-color;
      background-color: $white;
      outline: #fff solid 2px;
    }
    &:not(:disabled):not(.disabled){
      color: $main-font-color;
      background-color:  $white;
      box-shadow:none;
      &:hover{
        color: $main-font-color;
        background-color:  $white;
      }
    }
  }
  &-green, &.bg-green{
    color: $main-font-color;
    &:not(:disabled):not(.disabled):active, 
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active:focus, 
    &:not(:disabled):not(.disabled).active:focus,
    &:not(:disabled):not(.disabled):focus{
      color: $main-font-color;
      background: linear-gradient(135deg, #35A85A 0%, #4DA166 100%);
    }
    &:not(:disabled):not(.disabled){
      color: $main-font-color;
      background: linear-gradient(135deg, #35A85A 0%, #4DA166 100%);
      box-shadow:none;
      &:hover{
        color: $main-font-color;
        background: linear-gradient(135deg, #35A85A 0%, #4DA166 100%);
      }
    }
  }
  &-outline{
      &-default{
          border: 2px solid $btn-default-border;
          padding: 0.8rem 1.5rem;
          
          &.white{
            border-color: $white;
            color: $white;
            box-shadow: none;
            &:hover{
              background-color: $white;
              color: $main-font-color;
            }
          }
          &:hover{
              background-color: $btn-default;
              color: #fff;
          }
      }
      &-danger{
          color: #BA4640;
          border: 1px solid #BA4640;
          background-color: #fff;
          &:hover {
            background-color: #BA4640 !important;
            color: #fff;
          }
          &:focus {
            background-color: #BA4640 !important;
            color: #fff;
          }
      }
      &-primary{
        color: #357BCF;
        border: 1px solid #357BCF;
        background-color: #fff;
        &:hover {
          background-color: #357BCF !important;
          color: #fff;
        }
        &:focus {
          background-color: #357BCF !important;
          color: #fff;
        }
      }
      &-secondry{
        color: $white;
        border: 1px solid $brand-color;
        background-color: $brand-color;
        &:hover {
          background-color: $brand-color !important;
          color: #fff;
        }
        &:focus {
          background-color: $brand-color !important;
          color: #fff;
        }
      }
  }
}
.btn-black {
  background-color: #343434;
  color:#FFFFFF;
  &:hover {
    background-color: #FFA400;
    color:#FFFFFF;
  }
  &:focus {
    background-color: #FFA400;
    color:#FFFFFF;
  }
}
.btn-white {
  &:hover {
    background-color: #343434;
    color: $white-color;
  }
  &:focus {
    background-color: #343434;
    color: $white-color;
  }
  &:not(:disabled):not(.disabled):hover{
    background-color: #343434;
    color: $white-color;
  }
}
.bg-white.outline-primary {
  color: #357BCF;
  border: 1px solid #357BCF;
  &:hover {
    background-color: #357BCF !important;
    color: #fff;
  }
  &:focus {
    background-color: #357BCF !important;
    color: #fff;
  }
}
a.outline-primary {
  color: #357BCF;
  border: 1px solid #357BCF;
  background-color: #fff;
  &:hover {
    background-color: #357BCF !important;
    color: #fff;
  }
  &:focus {
    background-color: #357BCF !important;
    color: #fff;
  }
}
a.outline-danger {
  color: #BA4640;
  border: 1px solid #BA4640;
  background-color: #fff;
  &:hover {
    background-color: #BA4640 !important;
    color: #fff;
  }
  &:focus {
    background-color: #BA4640 !important;
    color: #fff;
  }
}
.bg-white.outline-danger {
  color: #BA4640;
  border: 1px solid #BA4640;
  &:hover {
    background-color: #BA4640 !important;
    color: #fff;
  }
  &:focus {
    background-color: #BA4640 !important;
    color: #fff;
  }
}
.link-btn   {
  padding: 0.5rem 0.8rem; display: inline-flex; align-items: center; justify-content: center; background-color:$white-color;
}
.right-checkbox { position: relative; background-color: #fff; 
  &::before{
    position: absolute;
  display: inline-block;
  transform: rotate(45deg);
  height: 12px;
  width: 6px;
  border-bottom: 3px solid #357BCF;
  border-right: 3px solid #357BCF;
  content: '';
  }
}
.dark-right-checkbox { position: relative; background-color: #357BCF; 
  &::before{
    position: absolute;
  display: inline-block;
  transform: rotate(45deg);
  height: 12px;
  width: 6px;
  border-bottom: 3px solid #fff;
  border-right: 3px solid #fff;
  content: '';
  }
}
.cross-checkbox { position: relative; background-color: #fff;
  &::before{
    position: absolute;
    left: 7px;
    content: ' ';
    height: 18px;
    width: 2px;
    background-color: #357BCF;
    transform: rotate(45deg);
  }
  &::after{
    position: absolute;
    left: 7px;
    content: ' ';
    height: 18px;
    width: 2px;
    background-color: #357BCF;
    transform: rotate(-45deg);
  }
}

.dark-cross-checkbox { position: relative; background-color: #BA4640;
  &::before{
    position: absolute;
    left: 7px;
    content: ' ';
    height: 18px;
    width: 2px;
    background-color: #fff;
    transform: rotate(45deg);
  }
  &::after{
    position: absolute;
    left: 7px;
    content: ' ';
    height: 18px;
    width: 2px;
    background-color: #fff;
    transform: rotate(-45deg);
  }
}
.react-select-container{
	.react-select__indicator-separator {
		display: none;
	}
	&.react-select__multi{
		.react-select__value-container{
			padding:4px; 
		}
	}
	.react-select__value-container{
		padding: 0 4px; 
	}
	&.react-select__tagStyle{
		.react-select__option{
			display: inline-block;
			overflow: unset;
			width: auto;
			border: none!important;
			border-radius: 0;
		}
	} 
	&.select-style-2{
		.mr-3{
			margin-right: 0!important;
		}
		.react-select__value-container{
			padding: 0;
		}
		.react-select__control{
			border: none;
			min-height: auto;
			min-width: 8rem;
		}
		.react-select__menu{
			min-width: 128px;
			right: 0;
		}
	}
	&.select-border-danger{
		.react-select__control{
			border-color: $color-danger!important;
		}
	}
	.react-select{
		&__menu{
			margin-top: -1px;
			border-radius: 2px;
			box-shadow: 0 4px 7px 0 rgba(52, 52, 52, 0.25);
			border: solid 1px #e9ebed;
			border: 0;
			overflow: hidden;
			border-radius: 2px;
		}
		
		&__control{
			font-size: 1.4rem;
			color: #343434;
			border-radius: 2px;
			border: 1px solid #D3D3D3;
			min-height: 4.1rem;
			box-shadow: none;
			line-height: 1;
			&:hover{
				border-color:#D3D3D3;
				box-shadow: none;
			}
			&--is-focused{
				border-color:#D3D3D3;
				box-shadow: none;
			}
			&--menu-is-open{
				.react-select__indicator{
					svg{
						// transform: rotate(180deg);
					}
				}
			}
			&--is-disabled{
				background-color: #fff;
				// opacity: 40%;
			}
			.react-select__value-container{
				padding: 0 4px; 
			}
		}
		
		&__value-container{
			padding-right: 12px;
			padding-left: 12px;
		}
		&__single-value{
			font-size: 1.4rem;
			color: $main-font-color;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		&__placeholder{
			color: #757575;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		&__multi-value{
			background-color: $primary-color;
			color: $white-color;
			margin:4px;
			&__label{
				color: $white-color;
				font-size: 1.4rem;
			}
		}
		&__indicator{
			padding-left: 0;
			svg{
				transition: 0.2s ease;
			}
		}
		&__menu-list{
			padding:0;
			max-height: 360px;
		}
		&__option{
			font-family: $SFProSemibold;
			font-size: 1.4rem;
			padding:1rem 1.5rem;
			color: #343434;
			border: 1px solid transparent;
			border-left: 1px solid #e9ebed;
			// border-right: 1px solid #e9ebed;
			line-height: 1;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			&:first-child{
				border-top: 1px solid #e9ebed;
				border-top-left-radius: 2px;
				border-top-right-radius: 2px;
			}
			&:last-child{
				border-bottom: 1px solid #e9ebed;
				border-bottom-left-radius: 2px;
				border-bottom-right-radius: 2px;
			}
			&--is-selected{
				background-color: #157CF7;
				color: #fff;
				border: 1px solid #126BD6!important;
			}
			&--is-focused{
				background-color: #157CF7;
				color: #fff;
				border: 1px solid #126BD6!important;
			}
		}
	}
}
.btn-prv {
  background-color: #357BCF;
  color: #fff;
  width: auto !important;
  padding: 0.3rem 1rem !important;
  display: flex; align-items: center; justify-content: center;
  span {
    max-width: 0;
    transition: 0.3s;
    overflow: hidden;
    display: none;
    margin-left: 1rem;
  }
  &:hover{ color: #fff;
    span { max-width: unset; display: flex; }
  }
}
.color-red      { color: #BA4640; }
.custom-datepicker.color-red {color: #BA4640!important;}
input.error     { border-color: #BA4640;
  &:focus { border-color: #BA4640;}
}