@import "../../scss/utils/variables";


.referral-card{

    .card-box{

        .card__header{
            padding: 40px 50px 0px;

            .card__pagination{
                margin-right: 25px;
            }
            .list-group-horizontal{
                margin-left: 15px;
            }
            .help__inner__search{
                flex:0 0 545px;
                margin: 0 40px 0 auto;
            }
            .filter__body{
                .custom-form{
                    margin: 0px -10px;
                    .form-group{
                        width: 20%;
                    }
                }
            }
        }
    }
    .card-box-body{
        padding: 10px 50px 40px 50px;

        table{
            th:first-child,
            td:first-child{
                padding-left: 0;
            }
        }
    }
    
}

@media screen and (max-width:1470px){
    .referral-card{

        .card-box{
    
            .card__header{
                 
                .help__inner__search{
                    flex:1;
                }
                 
            }
        }
    }
        
}
@media screen and (max-width:1199px){
    .referral-card{

        .card-box{
    
            .card__header{

                .filter__body{
                    .custom-form{
                        .form-group{
                            width: 25%;
                        }
                    }
                }
                 
            }
        }
    }
    
}
@media screen and (max-width:1024px){
    .referral-card{

        .card-box{
    
            .card__header{

                .d-flex.col{
                    flex-wrap: wrap;
                    .help__inner__search{
                        flex:0 0 100%;
                        max-width: 100%;
                        margin: 0 0 10px 0;
                    }
                    .btn-filter{
                        margin-left: auto;
                    }
                }
                .filter__body{
                    .custom-form{
                        .form-group{
                            width: 50%;
                            margin-top: 10px;
                        }
                    }
                }
                 
            }
        }
    }
    
}
@media screen and (max-width:900px){
    .referral-card{

        .card-box{
    
            .card__header{
                padding: 25px 20px 0px;
                 
                .card__pagination{
                    margin-right: 0px;
                }
            }
            
        }
        .card-box-body{
            padding:10px 20px 25px;
        }
    }
    
}
