.card{
	.table {
		color: $main-font-color;
		&.border-bottom-0{
			tr { 
				&:last-child{
					border-bottom:0;
				}
			}
		}
	   thead {
			th {
				padding-bottom: 1.1rem;
				text-align: left;
				border:0;
				font-family: $SFProTextBold;
				font-weight: 700;
			}
		}
		thead {
			tr{
				border-bottom: 0;
			}
		}
		th,td{
			font-family: $SFProTextRegular;
			padding:1.4rem 1.5rem;
			text-align: left;
			border: 0;
			white-space: nowrap;
			&:last-child{
				padding-right:3rem;
			}
			&:first-child{
				padding-left:3rem;
			}
		}
		th:first-of-type { padding-left: 0;}
		td:first-of-type { padding-left: 0;}
		
		tr { 
			border-bottom: solid 1px #ebebeb;
			&:last-child{
				// border-bottom:0;
			}
		 }
	}
}
.table{
	thead{
		th{
			font-family: $title-font;
		}
	} 
} 
.multi__checkbox{
	&.px-50{
		padding-left: 3rem;
	}
	.table{
		// margin-left: -2rem;
		thead{
			tr{
				border-left: 5px solid transparent;
				th{
					padding-left: 1.5rem!important;
				}
			}
		}
		tbody{
			tr{
				position: relative;
				border-left: 5px solid transparent;
				td,th{
					vertical-align: middle;
					padding-left: 1.5rem!important;
				}
				&.active{
					background-color: #f8f9f9;
					border-left: 5px solid #343434;
				}
			}
		}
		
	}
}
.sub-page{
    &__table{
        height: calc(100vh - 26rem);
		padding:0 2rem;
		.w-35{
			width: 35%;
		}
		.table th, 
		.table td{
            &:first-child {
                padding-left: 0;
                // width: 35%;
            }
            &:last-child {
                padding-right: 0;
            }
        }
    }
}
@media (min-width: 1575px) {
    .table th{
    	white-space: nowrap;
    }
}

