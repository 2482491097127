@import "../../scss/utils/variables";
.form-control{
	font-size: 1.5rem;
	color: #343434;
	border-radius: 4px;
	border: 1px solid #979797;
	box-shadow: none;	
	padding:12px;
	line-height: 2.2rem;
	height: 5rem;
	&.invalid-field{
		border-color:$color-danger!important;
	}
	&.inputerror{
        .form-control{
            box-shadow: inset  0 0 0 0.2rem $color-danger!important;
        }
    }
	&:focus{
		color: #343434;
		box-shadow: none;
		outline: none;
		border-color:#979797;
	}
	&:disabled{
		color: #A7A7A7;
	}
}
.invalid-field{
    // box-shadow: inset  0 0 0 0.2rem #d47074;
}
.noboldlabel {
	strong{
		font-weight: 400;
	}
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.custom-checkbox{
	position: relative;
	padding-left: 0;
	&.sm{
		.custom-control-label:before{
			height: 16px;
			width: 16px;
			top: 50%;
			// margin-top: -8px;
		}
		input:checked + .custom-control-label:after{
			height: 10px;
			width: 10px;
			left: 3px;
			top: 50%;
			// margin-top: -4px;
		}
		.custom-control-label{
			padding-left: 34px;
		}
	}
	.custom-control-input{
		position: relative;
		height: 0px;
		width:0px;
	}
	.custom-control-label{
		font-size: 15px;
		margin: 0px;
		cursor:pointer;
		position: relative;
		padding-left: 40px;
	}
	.custom_check_toggle{
		display: inline-block;
		cursor:pointer;
		img{
			max-width: 18px;
			margin-left: 10px;
		}
	}
	.custom-control-label:before{
		content:"";
		height: 20px;
		width: 20px;
		position: absolute;
		left: 0;
		top:0;
		background-color:#fff;
		border-radius:2px;
		border: 1px solid #424242;
	}
	input:checked + .custom-control-label:before{
		background: #357BCF;
		border-color: #357BCF;
	}
	.custom-control-input:focus ~ .custom-control-label::before{
		box-shadow:none
	}
	.custom-control-input:focus:not(:checked) ~ .custom-control-label::before{
		box-shadow:none;
		border-color: #424242;
		
	}
	input:checked + .custom-control-label:after{
		content:"";
		background-image:url('../../img/IconCheck.svg');
		height: 12px;
		width: 12px;
		position: absolute;
		left: 2px;
		top: 10px;
		background-size: contain;
		background-repeat: no-repeat;
	}
}
.invoice-input .custom-checkbox .custom-control-input { top: 5px; }
.invoice-input .custom-checkbox input:checked + .custom-control-label:after { top: 12px; }
.custom-control-inline { display: inline-block; }
.invoice-input .custom-checkbox .custom-control-label { font-family: "SFProText-Regular"; }
.form{
	padding: 0 5rem;
	label.nowrap{
		width: 100%;
		max-width: 16rem;
		white-space:normal;
	}
	&.mw-141{
		.form-control,
		.react-select-container,
		.dropdown-select{
			max-width: 39.6rem;
		}
		.col-form-label{
			max-width: 163px;
		}
	}
}

.form-group{
	margin-bottom: 2rem;
	.notes{
		font-size: 11px;
		color: #656565;
	}
	.input-group-text{
		background-color: transparent;
		&.invalid-field{
			border-color: #BA4640;
		}
	}
	.input-group{
		&.custom__append{
			// padding-right: 2rem;

			.input-group-append{
				position: absolute;
				right: 0;
				top: 0;
				bottom: 0;
				display: flex;
				align-items: center;
				padding: 0 1rem;
				z-index: 3;
				.input-group-text{
					background-color: transparent;
					border-left: 0;
				}
			}
		}
		
	}
	
}
.react-datepicker-popper{
	z-index: 9;
}
.react-datepicker{
	font-size: 1.5rem;
	line-height: 1.5;
	border-radius: 2px;
	border: solid 1px #e9ebed;
	background: #ffffff;
	box-shadow: 0px 4px 7px rgba(52,52,52,0.25);
	&__day,&__time-name{
		width: 4.4rem;
		line-height: 4.4rem;
		color: #157cf7;
		margin: 0;

		&--selected, &--in-selecting-range, &--in-range, 
		&--selected, &--in-selecting-range, 
		&-text--in-range, &-text--selected, 
		&-text--in-selecting-range, &-text--in-range{
			color: #ffffff;
		}
	}
	&__day--disabled, 
	&__month-text--disabled, 
	&__quarter-text--disabled{
		cursor: default;
		color: #ccc;
	}
	&__day{
		border-bottom: 1px solid #ebebeb;
		border-right: 1px solid #ebebeb;
		&--outside-month{
			color: #d3d3d3;
		}
	}
	&__day--keyboard-selected, 
	&__month-text--keyboard-selected, 
	&__quarter-text--keyboard-selected{
		border-radius: 0rem;
		background-color: #2a87d0;
		color: #fff;
		&:hover{
			background-color: #2a87d0;
			color: #fff;
			border-radius: 0rem;
		}
	}
	&__day-names{
		margin: 0 1.2rem 0rem 1.2rem;
		border: 1px solid #ebebeb;
		border-bottom: none;
	}
	&__day-name{
		width: 4.4rem;
		padding: 0.5rem;
		color: #343434;
		border-right: 1px solid #ebebeb;
		margin: 0;
		&:last-child{
			border-right: none;
		}
	}
	&__header{
		background: #fff;
		border-bottom:none;
	}
	&__month{
		margin:0 1.2rem 1.2rem 1.2rem;
		border: 1px solid #ebebeb;
	}
	&__month-dropdown-container--scroll{
		margin: 0 30px 0 0px;
	}
	&__year-read-view--down-arrow, 
	&__month-read-view--down-arrow, 
	&__month-year-read-view--down-arrow{
		top: 5px;
	}
	&__year-dropdown, 
	&__month-dropdown, 
	&__month-year-dropdown{
		background-color: #ffffff;
	}
	&__year-option, 
	&__month-option, 
	&__month-year-option{
		color: #343434;
		padding: 0.5rem 1rem;
		
		&:hover{
			background-color: #357BCF;
			color: #ffffff;
		}
		
	}
	&__year-dropdown--scrollable, 
	&__month-dropdown--scrollable, 
	&__month-year-dropdown--scrollable{
		height: 200px;
		::-webkit-scrollbar {
			width: 6px!important;  /* Remove scrollbar space */
		}
		/* Optional: show position indicator in red */
		::-webkit-scrollbar-thumb {
			background: #FF9F00!important;
		}
		::-webkit-scrollbar-thumb:hover {
			background: #FF9F00;
		}
	}

	&__year-dropdown--scrollable{
		display: flex;
		flex-direction: column-reverse;
	}

	&__year-option{
		&:hover{
			background-color: #357BCF;
			color: #ffffff;
		}
		&:first-of-type,&:last-of-type{
			background-color: transparent!important;
		}
	}
	
	&__navigation{
		&--next{
			border-left-color: $primary-color;
		}
		&--previous{
			border-right-color: $primary-color;
		}
		&--years-upcoming{
			border-color: #424242;
			border-style: solid;
			border-width: 3px 3px 0 0;
			content: "";
			display: block;
			height: 9px;
			top: 50%;
			width: 9px;
			transform: rotate(316deg);
		}
		&--years-previous{
			border-color: #424242;
			border-style: solid;
			border-width: 3px 3px 0 0;
			content: "";
			display: block;
			height: 9px;
			top: 50%;
			width: 9px;
			transform: rotate(136deg);
		}
	}
	&__current-month, 
	&-time__header, 
	&-year-header{
		font-size: 1.8rem;
		color: #343434;
	}
	&__triangle{
		display: none;
	}
}


.form-control{
	font-size: 1.4rem;
	color: #343434;
	border-radius: 2px;
	border: 1px solid #D3D3D3;
	height: 4.1rem;
	box-shadow: none;	
	padding:12px;
	&:focus{
		color: #343434;
		box-shadow: none;
		outline: none;
		border-color:#D3D3D3;
	}
}
.col-form-label{
	font-family: $SFProTextBold;
	font-size: 1.5rem;
	color: #343434;
	display: block;
	font-weight: 500;
}
//radio buttons
.custome__image__box {
	&.custom-checkbox{
		.custom-control-label{
			&:before {
				top: 0.3rem;
				transform: translate(0px, 0%);
			}
			&:after {
				top: 0.3rem;
				transform: translate(0px, 0%);
			}
		}
	}
}

.custom-checkbox{
	padding-left: 0;
	margin-right: 0;
	&.font-weight-normal{
		.custom-control-label{
			font-family: $body-font;
		}
	}
	.custom-control-input{
		height: 1.5rem;
		width: 1.5rem;
		&:focus ~ .custom-control-label::before {
			box-shadow:none!important;
		}
		position: absolute;
		left: 0;
		opacity: 0;
		top: 10px;
	}
	.custom-control-label{
		padding-left: 2.5rem;
		&:before {
			top: 50%;
			left: 0;
			width: 1.5rem;
			height: 1.5rem;
			box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.26);
			// box-shadow: none!important;
			border: solid 1px #b0b0b0;
			border-radius: 0.3rem !important;
			// z-index: 1;
			transform: translate(0px, -50%);
		}
		&:after {
			top: 50%;
			left: 0;
			width: 1.5rem;
			height: 1.5rem;
			// z-index: 11;
			background-size: 10px;
			background-position: center;
			transform: translate(0px, -50%);
			box-shadow: none!important;
		}
	}
}
.form-check{
	.form-check-label{
		color: #3E3E3E;
	}
}
.form-check-input{
	border: none;
	background-color: transparent;
}
.form-check-input[type=radio]{
	background-image: url(	data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iNyIgY3k9IjciIHI9IjYuNSIgc3Ryb2tlPSIjM0UzRTNFIi8+Cjwvc3ZnPgo=);
}
.form-check-input:checked[type=radio]{
	background-color: transparent;
	border: none;
	background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iNyIgY3k9IjciIHI9IjYuNSIgc3Ryb2tlPSIjM0UzRTNFIi8+CjxjaXJjbGUgY3g9IjciIGN5PSI3IiByPSI0LjUiIGZpbGw9IiMzRTNFM0UiIHN0cm9rZT0iIzNFM0UzRSIvPgo8L3N2Zz4K);
}
.custom-switch{
	.custom-control-label{
		&:before{
			height: 1.8rem;
			width: 1.8rem;	
			border-radius: 1px;
		}
		&:after{
			width: calc(1rem - 4px);
			height: calc(1.8rem - 4px);
			border-radius: 1px;
		}

	}
	.custom-control-input {
		&:focus ~ .custom-control-label::before {
			box-shadow:none!important;
		}
		&:checked{
			~ .custom-control-label{
				&::after{
					background-color: #fff;
					// -webkit-transform: translateX(0rem);
					// transform: translateX(0rem);
				}
			}
		}
		
	}
}
.custome__progress{
	position: relative;
	z-index: 3;
	.clear__icon{
		position:absolute;
		right: 0;
		z-index: 5;
		cursor: pointer;
	}
}
.form-group-with-error{
	position: relative;
	.text-danger{
		position: absolute;
		right: 0;
		top: -18px;
		font-size: 12px;
	}
}
.react-select-container{
	.react-select__indicator-separator {
		display: none;
	}
	&.react-select__multi{
		.react-select__value-container{
			padding:4px; 
		}
	}
	.react-select__value-container{
		padding: 0 4px; 
	}
	&.react-select__tagStyle{
		.react-select__option{
			display: inline-block;
			overflow: unset;
			width: auto;
			border: none!important;
			border-radius: 0;
		}
	} 
	&.select-style-2{
		.mr-3{
			margin-right: 0!important;
		}
		.react-select__value-container{
			padding: 0;
		}
		.react-select__control{
			border: none;
			min-height: auto;
			min-width: 8rem;
		}
		.react-select__menu{
			min-width: 128px;
			right: 0;
		}
	}
	&.select-border-danger{
		.react-select__control{
			border-color: $color-danger;
		}
	}
	.react-select{
		&__menu{
			margin-top: -1px;
			border-radius: 2px;
			box-shadow: 0 4px 7px 0 rgba(52, 52, 52, 0.25);
			border: solid 1px #e9ebed;
			border: 0;
			overflow: hidden;
			border-radius: 2px;
		}
		
		&__control{
			font-size: 1.4rem;
			color: #343434;
			border-radius: 2px;
			border: 1px solid #D3D3D3;
			min-height: 4.1rem;
			box-shadow: none;
			line-height: 1;
			&:hover{
				border-color:#D3D3D3;
				box-shadow: none;
			}
			&--is-focused{
				border-color:#D3D3D3;
				box-shadow: none;
			}
			&--menu-is-open{
				.react-select__indicator{
					svg{
						// transform: rotate(180deg);
					}
				}
			}
			&--is-disabled{
				background-color: #e9ecef;
				opacity: 0.6;
			}
			.react-select__value-container{
				padding: 0 4px; 
			}
		}
		
		&__value-container{
			padding-right: 12px;
			padding-left: 12px;
		}
		&__single-value{
			font-size: 1.4rem;
			color: $main-font-color;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		&__placeholder{
			color: #757575;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		&__multi-value{
			background-color: $primary-color;
			color: $white-color;
			margin:4px;
			&__label{
				color: $white-color;
				font-size: 1.4rem;
			}
		}
		&__indicator{
			padding-left: 0;
			svg{
				transition: 0.2s ease;
			}
		}
		&__menu-list{
			padding:0;
			max-height: 360px;
		}
		&__option{
			font-family: $SFProSemibold;
			font-size: 1.4rem;
			padding:1rem 1.5rem;
			color: #343434;
			border: 1px solid transparent;
			border-left: 1px solid #e9ebed;
			// border-right: 1px solid #e9ebed;
			line-height: 1;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			&:first-child{
				border-top: 1px solid #e9ebed;
				border-top-left-radius: 2px;
				border-top-right-radius: 2px;
			}
			&:last-child{
				border-bottom: 1px solid #e9ebed;
				border-bottom-left-radius: 2px;
				border-bottom-right-radius: 2px;
			}
			&--is-selected{
				background-color: #157CF7;
				color: #fff;
				border: 1px solid #126BD6!important;
			}
			&--is-focused{
				background-color: #157CF7;
				color: #fff;
				border: 1px solid #126BD6!important;
			}
		}
	}
}

.dropdown{
	&-select{
		.react-select__indicator-separator {
			display: none;
		}
		.flag__img{
			margin-right: 1rem;
		}
		
		.react-select{
			&__menu{
				position: relative;
				box-shadow:none;
				border: none;
				margin-top: 0;
				margin-bottom: 0;
			}
			&__control{
				font-size: 1.4rem;
				color: #343434;
				border-radius: 2px;
				border: 1px solid #D3D3D3;
				min-height: 4.1rem;
				box-shadow: none;
				margin-bottom: 1rem;
				&:hover{
					border-color:#D3D3D3;
					box-shadow: none;
				}
				&--is-focused{
					border-color:#D3D3D3;
					box-shadow: none;
				}
				&--menu-is-open{
					.react-select__indicator{
						svg{
							// transform: rotate(180deg);
						}
					}
				}
			}
			&__value-container{
				padding-right: 1.2rem;
				padding-left: 1.2rem;
			}
			&__single-value{
				font-size: 1.4rem;
				color: $main-font-color;
			}
			&__placeholder{
				color: #757575;
			}
			&__multi-value{
				background-color: $primary-color;
				color: $white-color;
				&__label{
					color: $white-color;
				}
			}
			&__indicator{
				svg{
					transition: 0.2s ease;
				}
			}
			&__menu-list{
				padding:0;
				max-height: 36rem;
				right: -1.6rem;
			}
			
			&__option{
				font-family: $SFProSemibold;
				font-size: 1.4rem;
				// padding:11px 15px;
				padding: 0;
				color: #343434;
				border: 1px solid transparent;
				.custome__opetion{
					margin-left: -1.5rem;
				}
				.flag__img{
					padding: 1rem;
					text-align: center;
					border: 1px solid transparent;
					margin-right: 0;
				}
				.select__label{
					padding: 1rem;
					width: 100%;
					border: 1px solid transparent;
				}
				&--is-selected{
					background-color: transparent!important;
					// color: #fff;
					border: 1px solid transparent;
					.select__label{
						background-color: #157CF7;
						color: #fff;
						border: 1px solid #126BD6;
						border-top-left-radius: 2px;
						border-bottom-left-radius: 2px;
					}
				}
				&--is-focused{
					background-color: transparent!important;
					// color: #fff;
					border: 1px solid transparent;
					.select__label{
						background-color: #157CF7;
						color: #fff;
						border: 1px solid #126BD6;
						border-top-left-radius: 2px;
						border-bottom-left-radius: 2px;
					}
				}
			}
		}
		.dropdown-menu{
			margin: 0;
			padding: 15px;
			margin-top: -1px;
			-webkit-box-shadow: 0 4px 7px 0 rgba(52, 52, 52, 0.25);
			box-shadow: 0 4px 7px 0 rgba(52, 52, 52, 0.25);
			border: solid 1px #e9ebed;
		}
	}
}
.react-search-field{
	.react-search__indicator-separator{
		display: none;
	}
	.react-search{
		&__control{
			font-size: 1.4rem;
			color: #343434;
			border-radius: 2px;
			border: 1px solid #D3D3D3;
			min-height: 41px;
			box-shadow: none;
			&:hover{
				border-color:#D3D3D3;
				box-shadow: none;
			}
			&--is-focused{
				border-color:#D3D3D3;
				box-shadow: none;
			}
			&--menu-is-open{
				.react-select__indicator{
					svg{
						// transform: rotate(180deg);
					}
				}
			}
		}
		&__menu{
			margin-top: -1px;
			border-radius: 2px;
			box-shadow: 0 4px 7px 0 rgba(52, 52, 52, 0.25);
			border: solid 1px #e9ebed;
			border: 0;
			overflow: hidden;
			border-radius: 2px;
		}
		&__menu-list{
			padding:0;
			max-height: 360px;
		}
		&__option{
			font-family: $SFProSemibold;
			font-size: 1.4rem;
			padding:1.1rem 1.5rem;
			color: #343434;
			border: 1px solid transparent;
			border-left: 1px solid #e9ebed;
			border-right: 1px solid #e9ebed;
			
			&:first-child{
				border-top: 1px solid #e9ebed;
				border-top-left-radius: 2px;
				border-top-right-radius: 2px;
			}
			&:last-child{
				border-bottom: 1px solid #e9ebed;
				border-bottom-left-radius: 2px;
				border-bottom-right-radius: 2px;
			}
			&--is-selected{
				background-color: #157CF7;
				color: #fff;
				border: 1px solid #126BD6!important;
			}
			&--is-focused{
				background-color: #157CF7;
				color: #fff;
				border: 1px solid #126BD6!important;
			}
		}
	}
}
.payment_module{
	max-width: 430px;
}
.change__location{
	&__sort__link{
		.nav{
			&-link{
				font-size: 2.5rem;
				color: #D3D3D3;
				&:hover,&.active{
					color: $main-font-color;
				}
			}
		}
	}
	&__header{
		position: relative;
		z-index: 5;
	}
	&__list{
		.list-group{
			&-item{
				padding: 2rem 4rem;
				font-size: 1.4rem;
				margin-top: -1px;
				line-height: 22px;
				&:hover{
					background-color: $primary-color;
					color: $white-color;
					.list-group-item-heading{
						color: $white-color;
					}
				}
				&.active{
					.list-group-item-heading{
						color: $white-color;
					}
				}
			}
			&-item-heading{
				font-size: 1.5rem;
			}
		}
	}
}
.pagination{
	.page-item{
		&.active{
			.page-link{
				background-color: transparent;
				color: $primary-color;
			}
		}
	}
	.page-link{
		font-size: 1.5rem;
		color: #757575;
		border: none;
		padding: 1rem;
		outline: none;
		box-shadow:none;
		&:focus{
			box-shadow:none;
		}
		&:hover,&.active{
			background-color: transparent;
			color: $primary-color;
		}
	}
}
.form-group .react-datepicker-wrapper{
    display: block;
    width: 100%;
  }
  .custom-datepicker{
    background-image: url('../../images/calendar.svg');
    background-repeat: no-repeat;
    background-position-x:97%;
    background-position-Y:center;
  }
.invalid-field{
	.react-select__control{
		border-color: #BA4640;
	}
	.ql-container.ql-snow{
		border-color: #BA4640;
	}
	.ql-toolbar.ql-snow{
		border-color: #BA4640;
	}
}
@media screen and (max-width: 600px) {
	.invite_form{
	  .form-group-with-error .text-danger{
		position: relative;
		top: 0;
	  }
	  .justify-content-between{
		justify-content:start!important;
	  }
	}
  }