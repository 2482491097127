@import "../../scss/utils/variables";

.login {
  &__main {
    &__screen {
      min-height: 100vh;
      width: 100%;
      overflow: hidden;
      background: linear-gradient(180deg, #1a1a1a 0%, #2d2d2d 100%);
    }
  }
  &__page {
  }
  &__inner {
    display: flex;
    align-items: stretch;
    height: 100%;

    &__wc-text {
      // max-width: 1091px;
      // flex: 0 0 1091px;

      .wc-text-box {
        padding-left: 162px;
        padding-top: 366px;
        color: #ffffff;
        font-size: 51px;
        font-weight: bold;
        line-height: 76px;

        span {
          display: block;
          color: #979797;
        }
      }
    }
    &__form {
      max-width: 615px;
      width: 100%;
      background-color: #ffffff;
      padding: 70px;
      font-weight: normal;
      min-height: 100vh;

      .brand-icon {
        padding-top: 108px;
        padding-bottom: 160px;
        img {
          max-width: 468px;
          display: block;
          margin: 0 auto;
        }
      }
      .form-body {
        h4 {
          font-size: 2.5rem;
          font-weight: bold;
          line-height: 2.9rem;
          text-align: center;
          margin-bottom: 30px;
        }
        .form-group {
          margin-bottom: 20px;
          label {
            margin-bottom: 5px;
          }
        }
        .forgot-pw {
          text-align: right;
          margin-bottom: 0;
        }
      }

      .user-profile-row {
        height: 490px;
        display: flex;
        align-items: stretch;
        flex-wrap: wrap;
        align-content: flex-start;

        .user-profile-item {
          max-width: 135px;
          flex: 0 0 135px;
          margin-right: 34px;
          margin-bottom: 34px;

          &:nth-child(3n) {
            margin-right: 0;
          }
          &.mr-0 {
            margin-right: 0px;
          }

          .user-profile-box {
            height: 135px;
            width: 135px;
            border: 1px solid #343434;
            border-radius: 5px;
            background-color: #ffffff;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            margin-bottom: 14px;

            &.main-profile {
              background-color: #343434;
              border: 0px;
              p {
                background-color: #979797;
                position: absolute;
                margin: 0;
                left: 0;
                right: 0;
                bottom: 0;
                font-size: 1.5rem;
                line-height: 2.2rem;
                text-align: center;
                color: #ffffff;
                height: 22px;
              }
            }
            img {
              display: block;
            }
          }
          h4 {
            font-size: 1.5rem;
            line-height: 2.2rem;
            text-align: center;
            margin: 0;
            padding: 0 10px;
          }
        }
      }
      .pr-email-reset {
        padding-top: 66px;
        min-height: 550px;
        display: flex;
        flex-direction: column;

        .pr-title {
          font-size: 2.5rem;
          line-height: 5rem;
          text-align: center;
          b {
            display: block;
            font-weight: bold;
          }
        }
      }
    }
  }
}
