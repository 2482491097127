.card {
	border-radius: 2px;
	border:0;
	width: 100%;
	&--height-fluid-60{
		height: calc(100% - 30px);
	}
	&__header{
		padding:1.5rem 3rem 1.5rem 3rem;
		position:relative;
		z-index: 3;
		&__filter{
			padding:1.5rem 5rem;
			.btn-prv{
				margin-left: -5rem;
				width: 2.6rem;
				border-radius:5px;
				height: 2.6rem;;
			}
			.card__title{
				h3{
					// width: 200px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					text-transform: capitalize;
				}
				
			}
		}
	}
	.filter__body{
		label{
			font-family: $SFProSemibold;
		}
	}
	
	&__title{
		align-items: baseline;
	}
	&--sub_title{
		font-family: $SFProMedium;
		color: #757575;
	}
	&__pagination{
		font-family: $SFProSemibold;
		font-size: 1.5rem;
		height: 4.1rem;
		a{
			:hover{
				color: $primary-color;
			}
		}
	}
	&__searchBox{
		position: absolute;
		width: 100%;
		left: 0;
		padding: 1.5rem;
		top: 0px;
	}
}
.react-search-container{
	box-shadow: 0 10px 10px 0 rgba(52, 52, 52, 0.25);
	padding: 1.5rem;
	background-color: #fff;
	border-top: 1px solid #E9EBED;
	.search-dropdown{
		&__control{
			display: flex;
			border-radius: 2px;
			border: solid 1px #d3d3d3;
			background-color: #ffffff;
			width: 100%;
			line-height: 1.57;
			height: 4.1rem;
			box-shadow: none;
			&:hover{
				border-color:#D3D3D3;
				box-shadow: none;
			}
			&--is-focused{
				border-color:#D3D3D3;
				box-shadow: none;
			}
		}
		&:hover{
			border: solid 1px #d3d3d3;
		}
		&__indicator-separator{
			display: none;
		}
		&__menu{
			margin-top: 0;
			border: none;
			border-radius: 2px;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
			box-shadow: 0 10px 10px 0 rgba(52, 52, 52, 0.25);
			width: auto;
			left: 0;
			right: 0;
			padding-right: 1rem;
		}
		&__option{
			font-family: $SFProSemibold;
			font-size: 14px;
			padding:1rem 1.5rem;
			color: #343434;
			border: 1px solid transparent;
			&--is-selected,
			&--is-focused,
			&:active{
				background-color: #157CF7;
				color: #fff;
				border: 1px solid #126BD6;
			}
		}
	}
}

.magic__line{
	height: 100%!important;
	border: 0!important;
	background-color: $primary-color;
	z-index: -1;
}

.conversation_info_box{
	.btn-link{
		font-family: $SFProTextRegular;
		color: $color-gray;
		&.active{
			font-family: $SFProTextBold;
			color: $dark-color;
		}
	}
}
.color-primary { color: #357BCF; text-decoration: none;
	&:hover { color: #357BCF;}
}
.right-gray-border { border-right: 1px solid #EBEBEB; }
.left-gray-border { border-left: 1px solid #EBEBEB; }
.bg--gray  { background-color: #FBFBFB;}