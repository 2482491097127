

.table .sticky-column {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 1;
}

.sticky{
    position: sticky;
}

.color-yellow{
    color:#FF9F00;
}
.color-red{
    color:#FF4D54;
}
.color-green{
    color:#4DA166;
}
.visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within),.sr-only {
    position: absolute!important;
    width: 1px!important;
    height: 1px!important;
    padding: 0!important;
    margin: -1px!important;
    overflow: hidden!important;
    clip: rect(0,0,0,0)!important;
    white-space: nowrap!important;
    border: 0!important;
}