.linkExpiredPage{
    font-size: 24px;
    color: #000000;
    max-width: 840px;
    margin: 0 auto;
    padding: 15px;
    h1{
        color: #000000;
        font-size: 64px;
        line-height: 80px;
        font-weight: 700;
    }
    p{
        font-size: 24px;
        line-height: 35px;
        color: #000000;
    }
    a{
        color: #F99F1B;
    }

    .btn {
        border-radius: 5px;
        padding: 1rem 1.5rem;
        font-size: 16px;
        font-weight: 500;
        &-primary{
            &.brandBtn{
                color:#fff;
                margin-top: 20%;
                &:not(:disabled):not(.disabled):active, 
                &:not(:disabled):not(.disabled).active,
                &:not(:disabled):not(.disabled):active:focus, 
                &:not(:disabled):not(.disabled).active:focus,
                &:not(:disabled):not(.disabled):focus{
                  color: #fff;
                  background: linear-gradient(115.78deg, #F99F1B 17.31%, #FC631C 98.76%);
                  outline: #fff solid 1px;
                }
                &:not(:disabled):not(.disabled){
                  color: #fff;
                  background: linear-gradient(115.78deg, #F99F1B 17.31%, #FC631C 98.76%);
                  box-shadow:none;
                  &:hover{
                    color: #fff;
                    background: linear-gradient(115.78deg, #F99F1B 17.31%, #FC631C 98.76%);
                  }
                }
            }
          }
    }
}
@media screen and (width: 767px) {
  .invite_form{
    .form-group-with-error .text-danger{
      position: relative;
    }
    .justify-content-between{
      justify-content:start!important;
    }
  }
}