@import "../../scss/utils/variables";


.conatct{

    &__main{
        
        &__screen{
            background-color: #FFFFFF;
            padding: 40px;
        }
    }
    &__page{

    }
    &__inner{
         max-width: 1154px;

         .mw205{
             min-width: 205px;
         }
         &>h4{
            font-size: 2.5rem;
            line-height: 2.9rem;
            font-weight: bold;
            margin: 0 0 25px 0;
         }

         .conatct-row{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;

            .form-group{
                display: flex;
                align-items: center;
                justify-content: space-between;
                max-width: 549px;
                flex:0 0 549px;
                padding: 0px;

                &>label{
                    max-width: 153px;
                    flex:0 0 153px;
                    font-weight: bold;
                }
                .form-control,.react-select-container{
                    // max-width: calc(100% - 153px);
                    flex:0 0 calc(100% - 153px);
                    height: 40px;
                    border-color: #D3D3D3;
                    border-radius: 2px;
                    .invalid-field{
                        border-color: #BA4640 ;
                    }
                }
                
            }
         }
         .editor-row{
            
            margin-bottom: 40px;

            .form-group{
                display: flex;
                align-items: flex-start;
                justify-content: space-between;

                &>label{
                    max-width: 153px;
                    flex:0 0 153px;
                    font-weight: bold;
                    padding-top: 8px;
                }
                .text-editor{
                    // max-width: calc(100% - 153px);
                    flex:0 0 calc(100% - 153px);
                    
                    .ql-editor{
                        min-height: 379px;
                    }
                }
            }
         }
       
    }
    
}
.contact-us-confirm {

    .modal{
        width:calc(100% - 238px);
        left: 238px;
        background-color:transparent;

        .modal-dialog{

            max-width: 485px;

            .modal-content{
                background-color: #F8F8F8;
                border-radius: 0;

                .modal-body{
                    padding:40px 35px;

                    h4{
                        font-size: 1.5rem;
                        line-height: 2.2rem;
                        font-weight: bold;
                        margin: 0;
                        text-align: center;
                    }
                    p{
                        font-size: 1.5rem;
                        line-height: 2.2rem;
                        font-weight: normal;
                        margin: 0;
                        text-align: center;
                    }
                     
                     
                }
                .modal-footer{
                    padding:0 35px 35px 35px;
                    border-top:none;
                    justify-content:center;

                    .btn{
                        margin: 0;
                        width: 200px;
                        height: 50px;
                        border-radius:2px;

                        &:not(:first-child):last-child{
                            margin-left: auto;
                        }
                    }
                }
            }
        }
    }
    &.isFromTos{
        .modal-backdrop , .modal{
            width:100%;
            left: 0;
        }
       
    }
    .modal-backdrop{
        background-color:rgba(0,0,0,0.77) !important;
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter:blur(10px);
        opacity: 1 !important;
        width:calc(100% - 238px);
        left: 238px;
        display: block;
        top: 0;
    }
}

@media screen and (max-width:1560px) {
    .conatct__inner{
        max-width: 100%;
        .conatct-row {

            .form-group{
                max-width: 50%;
                flex: 0 0 50%;

                &:last-child{
                    &>label{padding-left: 10px;}
                }
            }
        }
    }
}
@media screen and (max-width:1024px) {
    .conatct__inner{
        .conatct-row {
            flex-wrap: wrap;
            margin-bottom: 0px;
            .form-group{
                max-width: 100%;
                flex: 0 0 100%;
                &:last-child{
                    &>label{padding-left: 0px;}
                }
            }
        }
        .editor-row{
            margin-bottom: 40px;
        }
    }
}