.status-bar{
	font-size: 62.5%;
	.card{
		border-radius: 2px;
		border:0;
		padding: 1.4em 0;
		min-height: 9.8em;
		.px-4{
			padding-left: 1.5em!important;
			padding-right: 1.5em!important;
		}
		.col {
			flex: auto;
			
		}
		&__box{
			padding:0.5em  4.35em;
		}
		&__body{
			font-size: 1.5em;
	        font-weight: 400;
	        white-space: nowrap;
	        line-height: 1.2;
			img { margin-left: 1.5rem; }
	    }
		&__count{
			
	        padding-right:1rem;
	        font-size: 6em;
			line-height: 0.8em;
			letter-spacing: 3px;
	    }
	    &__heading{
			font-weight: 700;
			&.btn-link{
				
			}
		}
		&__text{
			
			letter-spacing: 0.3px;
		}
	    &--small{
	    	// max-width: 20em;
	    	.card__body{
	    		font-size: 1.3em;
	    	}
	        .card__count{
	        	font-size: 3.8em;
	            padding-right:0.2em;
	        }
	    }
	    &--acct_date{
	        &__heading{
				
	            font-size: 1.800em;
	            margin-top: 0.4em;
				margin-bottom: 0;
				letter-spacing: -1px;
	        }
	        &__link {
				
				
				padding-top: 1rem;
	            .ticon{
	                padding-right:15px;
	                font-size: 1.125em;
	                &:before{
	                    
	                }
	            }
	            span{
	            	display: inline-block;
	            }
	        }
	    }
	}
}


@media (min-width: 1200px) and (max-width: 1400px) {
    .status-bar{
	    font-size: 50%;
    }
}
@media  (max-width: 990px) {
    .status-bar{
	    font-size: 60.5%;
	   .col {
	    	-ms-flex-wrap: wrap;
			flex-wrap: wrap;
			flex: 1 1 auto;
	    }
    }
}
@media (min-width: 1400px) and (max-width: 1500px) {
    .status-bar{
	    font-size: 45.5%;
    }
}
@media (min-width: 1500px) and (max-width: 1600px) {
    .status-bar{
	    font-size: 49%;
    }
}
@media (min-width: 1600px) and (max-width: 1700px) {
    .status-bar{
		// font-size: 53.5%;
		font-size: 45.5%;
    }
}
@media (min-width: 1700px) and (max-width: 1800px) {
    .status-bar{
	    font-size: 58%;
    }
}
@media (min-width: 1800px) and (max-width: 1900px) {
    .status-bar{
	    font-size: 60.5%;
    }
}
@media (min-width: 1920px){
	.custom-col-6 {
	    width: 48%;
	    -ms-flex: 0 0 48%;
	    flex: 0 0 48%;
	    max-width: 48%;       
	}
	.custom-col{
		max-width: 416px;
	}
	.mw455{
		max-width: 45.5rem;
	}
}

@media  (max-width: 700px) {
    .status-bar{
	    .card{
	    	&__count{
		        font-size: 3em;
		        line-height: 1.2;
		    }
		    &--small{
		    	.card__count {
	   				font-size: 2.8em;
	   			}
		    } 
	    }
    }
}
@media  (max-width: 640px) {
    .status-bar{
	    .card{
	    	&__box, &--small{
				flex: 1 1 100%;
				justify-content: center;
				max-width: 100em;
		    }
	    }
    }
}