@import "../../scss/utils/variables";
.shortingArrow{
    transition: all 0.3s ease;
    display: inline-block;
}
.activearrow{
    transform: rotate(180deg);
    transition: all 0.3s ease;
    display: inline-block;
}
.react-pdf__Page__svg{
    width: 100%!important;
    max-width: 840px!important;
    height: auto!important;
    svg{
        width: 100%!important;
        max-width: 840px!important;
        height: auto!important;
    }
}
.react-pdf__Page__annotations.annotationLayer{
    display: none;
}
span p{
    margin-bottom: 0;
}