@import "../../scss/utils/variables";

.tos{
    &__main{
        &__screen{
            max-width: 860px;
            max-height: 796px;
            margin: 0 auto 0 auto;
            // padding: 50px 0;
            min-height: calc(100vh - 186px);
            .tos__body{
                background-color: #fff;
                height: calc(100vh - 32rem);
                padding: 2rem 2rem 2rem 2rem;
            }
            &.fullHeight{
                max-height: 100%;
            }
        }
    }
    &__contact__screen{
        max-width: 1200px;
        margin: 0 auto;
        min-height: calc(100vh - 186px);
        .conatct__main__screen{
            min-height: calc(100vh - 186px);
        }
        .conatct__inner .editor-row{
            margin-bottom: 4rem;
        }
    }
    &__footer{
        max-width: 860px;
        width: 100%;
        margin: 0 auto;
    }
    &__inner{
         max-width: 1154px;
         padding: 0 20px;
    }
}


