@import "../../scss/utils/_variables.scss";
@import "../../scss/utils/_mixins.scss";

.profile-modal {

    .modal{
        width:calc(100% - 238px);
        left: 238px;
        background-color:transparent;

        .modal-dialog{

            width: 420px;

            .modal-content{
                background-color: #F8F8F8;
                border-radius: 0;

                .modal-body{
                    padding: 35px;

                    .user-profile-form{

                        .user-profile-row{
                            margin-top: 35px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
            
                            .user-profile-item{
                                max-width: 135px;
                                flex:0 0 135px;
                                margin-bottom: 50px;
            
                                .user-profile-box{
                                    height: 135px;
                                    width: 135px;
                                    border: 1px solid #343434;
                                    border-radius: 5px;
                                    background-color: #FFFFFF;
                                    position: relative;
                                    display: flex;
                                    align-items:center;
                                    justify-content: center;
                                    overflow: hidden;
                                    margin-bottom: 14px;
                                    
                                    &.main-profile{
                                        background-color: #343434;
                                        border:0px;
                                        p{
                                            background-color: #979797;
                                            position: absolute;
                                            margin: 0;
                                            left: 0;
                                            right: 0;
                                            bottom: 0;
                                            font-size: 1.5rem;
                                            line-height: 2.2rem;
                                            text-align: center;
                                            color: #FFFFFF;
                                            height: 22px;
                                        }
                                    }
                                    img{
                                        display: block;
                                    }
                                }
                                h4{
                                    font-size: 1.5rem;
                                    line-height: 2.2rem;
                                    text-align: center;
                                    margin: 0;
                                    padding: 0 10px;
                                }
                            }
                        }

                        .form-group{

                            label{
                                margin-bottom: 6px;
                            }
                            &:not(:last-child){
                                margin-bottom: 20px;
                            }

                            .form-control{
                                height: 40px;
                                border-radius: 4px;
                                // border-color: #D3D3D3;
                                padding: 8px 15px;
                            }
                        }
                        .user-profile-note{
                            padding-top: 25px;
                            padding-bottom: 85px;

                            p{
                                margin: 0;
                                font-size: 1.4rem;
                            }
                        }
                        
                    }
                    .user-profile-update{
                        font-size: 1.5rem;
                        margin: 7px 0 0 0;
                        text-align: center;
                        font-weight: bold;
                        line-height: 2.2rem;
                    }
                }
                .modal-footer{
                    padding:0 35px 35px 35px;
                    border-top:none;
                    justify-content:center;

                    .btn{
                        margin: 0;
                        width: 167px;

                        &:not(:first-child):last-child{
                            margin-left: auto;
                        }
                    }
                }
            }
        }
    }
    .modal-backdrop{
        background-color:rgba(0,0,0,0.77) !important;
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter:blur(10px);
        opacity: 1 !important;
        width:calc(100% - 238px);
        left: 238px;
        display: block;
        top: 0;
    }
}
