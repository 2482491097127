@import "../../scss/utils/_variables.scss";
@import "../../scss/utils/_mixins.scss";

.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    // width: 6rem;
    width: 23.8rem;
    overflow: hidden;
    transition: all .2s ease;
    z-index: 9;
    @include gradient_dark;
    
    &__logo{
        display: flex;
        align-items:center;    
        min-height: 6rem;
        // width: 6rem;
        width: 23.8rem;
        .logo{
            display: flex;
            flex-direction: row;
            &__icon{
                width: 6rem;
                text-align: center;
            }
            &__text{
                // display: none;
                display: inline-block
            }
        }
    }
    &:hover, &.active{
        width: 23.8rem;
        .sidebar{
            &__navigation {
                &__link{
                    width: 23.8rem;
                }
                &__text{
                    display: inline-block;
                }
            }
            &__logo{
                width: 23.8rem;
                .logo{
                    &__text{
                       display: inline-block;
                    }
                }
            }
        }
        & + .main-content  {
            margin-left:23.8rem ;
        }
        .toggle-btn{
            left:23.8rem ;
            transform: rotate(-180deg);
        }
        .sidebar__navigation__item--active{
            .sidebar__navigation__submenu{
                display: block;
            }
        } 
    }
    
    
    &__navigation {
        &__nav{

        }
        &__list{
            color: $white-color;
        }
        &__item{
            &.active{
                .sidebar{
                    &__navigation{
                        &__link{
                            background-color: $brand-color;
                            color: $white-color;
                        }
                    }
                }
            }
            // &--active{
            //     .sidebar__navigation__submenu{
            //         display: block;
            //     }
            // }
            .sidebar__navigation__submenu{
                display: none;
            }
            &:hover{
                .sidebar{
                    &__navigation{
                        &__submenu{
                            display: block;
                        }
                    }
                }
            };
            
        }
        &__link{
            font-family: $SFProMedium;
            font-size: 1.5rem;
            color: $white-color;
            padding: 1.1rem 0rem;
            display: block;
            position: relative;
            width: 6rem;
            height: 4.8rem;
            &:hover{
                background-color: $brand-color;
                color: $white-color;
            }
            &--active{
                background-color: $brand-color;
                color: $white-color;
                + .sidebar__navigation__submenu{
                    display: block;
                }
            }
        }
        &__icon{
            vertical-align: middle;
            width: 6rem;
            display: inline-block;
            text-align: center;
            vertical-align: middle;
            svg{
                max-width: 100%;
            }
        }
        &__text{
            display: none;
            vertical-align: middle;
        }
        &__submenu {
            display: none;
            background-color: $white-color;
            padding:8px 0 8px 6rem;
            >li {
                >a{
                    font-family: $SFProTextRegular;
                    color: #343434;
                    display: block;
                    font-size: 1.5rem;
                    line-height: 2.67;
                    &.active, &:hover {
                        color: #343434;
                        
                    }
                    &.active{
                        font-weight: 700;
                    }
                }
                
            }
        }
        &__bottom{
            &:before{
                content: '';
                opacity: 0.5;
                border-bottom: solid 2px rgba(249,159,27,0.5);
                display: block;
                height: 2px;
                margin: 3rem 0;
            }
            .sidebar__navigation__link{
                font-size: 1.4rem;
                height: 4rem;
                padding: 0.8rem 0;
            }
            .sidebar__navigation__icon{
                font-size: 1.5rem;
            }
        }
    }
}
.toggle-btn {
    position: fixed;
    left: 6rem;
    top: 50%;
    transition: all .2s ease;
    &.active{
        
        left: 23.9rem;
    }
    .ticon{
        font-size: 9px;
        &:before {
            color: $main-font-color;
        }
        
    }
}
.sidebar__navigation__link--active{
    .sidebar__navigation__submenu{
        display: block ;
    }
} 
