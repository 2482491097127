.modal { background-color: rgba(0, 0, 0, 0.85);
  .modal-sm{
    max-width: 47rem;
    width: 100%;
  }
  .btn.btn-md { max-width: 20rem; }
    &-title {
        margin-bottom: 0rem;
        font-family: $title-font;
    }
    &-dialog {
      max-width: 47rem;
      width: 100%;
      &.modal-lg{
        max-width: 90%;
        max-width: 140rem;
        &.stock_image_modal{
          max-width: 1358px;
          img{
            max-width: 1080px;
            max-height: 720px;
          }
        }
        .modal-content{
          // min-height: 860px;
          // max-height: 95vh;
        }
      }
    }
    &-header{
      border: none;
      padding: 2rem 2rem 0 2rem;
      .close{
        font-size: 4rem;
        color: $primary-color;
        font-weight: 400;
        opacity: 1;
        text-shadow: none;
        line-height: 0.7;
      }
    }
    &-body{
        padding:2rem;
        p:last-child{
            margin-bottom: 0;
        }
    }
    &.fade{
        &.show{
            .modal-dialog{
                transform:  translateY(-50%) scale(1);
            }
        }
        .modal-dialog{
            top: 50%;
            transform:  translateY(-50%) scale(0.1);
        }
    }
    &-backdrop{
        top: 6rem;
        &.show{
            opacity: 0.77;
        }
    }
    &-content {
      line-height: 1.67;
    }
    &-footer{
      border:0;
      padding: 0 2rem 2rem 2rem;
        >*{
            margin:0;
        }
    }
  }

  .modal-backdrop{
    display: none;
  }
  .custom-control-label{
    font-family: $SFProSemibold;
  }

  .form-group .react-datepicker-wrapper{
    display: block;
  }
  .custom-datepicker{
    background-image: url('../../images/calendar.svg');
    background-repeat: no-repeat;
    background-position-x:97%;
    background-position-Y:center;
  }
  .bg__readlight{
    background-color: rgba(255, 164, 0, 0.12);
    .ql-container,.ql-toolbar{
      background-color: transparent
    }
  }
  .textarea_section{
    position: relative;
    .form-control{
      padding-bottom: 53px;
    }
    .editer_buttons{
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      border-top: 1px solid #D3D3D3;
      .btn-link{
        font-size: 2rem;
      } 
    }
  }
.authorizationcode__inputgroup{
    .form-control{
        max-width: 4.2rem;
        height: 5rem;
        font-size: 2.5rem;
        color: #68737C;
        padding: 0.5rem;
    }
  }
  .text-left { text-align: left !important; }