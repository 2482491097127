@import "../../scss/utils/variables";


.licenses-card{

    .card-box{

        .card__header{
            padding: 40px 50px 0px;

            .card__pagination{
                margin-right: 25px;
            }
            .list-group-horizontal{
                margin-left: 15px;
            }
            .help__inner__search{
                flex:0 0 545px;
                margin: 0 40px 0 auto;
            }
            .card__title{
                .link-btn{
                    padding: 0.3rem 0.8rem;
                    font-size: 1.4rem;
                    margin: 0 15px 0 0;
                }
            }
            .filter__body{
                .custom-form{
                    margin: 0px -10px;
                    .form-group{
                        width: 20%;
                    }
                    label{
                        margin-bottom: 5px;
                    }
                }
            }
        }
    }
    .card-box-body{
        padding: 10px 50px 40px 50px;

        table{
            th:first-child,
            td:first-child{
                padding-left: 0;
            }

            .text-danger{
                color:#BA4640 !important;
            }
            .custom-checkbox{

                .custom-control-label{
                    &:before{
                        margin-top: 0;
                    }
                }
                input:checked + .custom-control-label{
                    &:after{
                        margin-top: 0;
                    }
                }
            }
        }
    }
    
}
.license-inner-card{
    background-color: #FFFFFF;
    

    .form-text{
        font-size: 1.5rem;
        line-height: 2.2rem;
        color:#343434 !important;
    }

    .license-inner-head{
        padding: 40px 50px 0 50px;

        h4{
            text-align: left;
            font-size: 2.5rem;
            line-height: 2.9rem;
            font-weight: bold;
            margin: 0 0 35px 0;
            position: relative;
            padding-left: 40px;

            .btn{
                height: 26px;
                width: 26px;
                border-radius:5px;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                left: 0;
                top: 1px;
            }
        }
    }
    .license-inner-content{
        &:not(.no-background):first-child{
            background-color: #FBFBFB;
        }
        padding: 40px 50px;

        h4{
            font-weight: bold;
            margin: 0 0 30px 0;
            font-size: 1.5rem;
            text-transform: uppercase;
        }
        .form-group{
            max-width: 549px;
            margin: 0 0 20px 0;

            label{
                flex:0 0 153px;
                max-width: 153px;
                font-weight: bold;
                padding: 0;
            }
            .col-sm-8{
                padding: 0;
                flex: 0 0 calc(100% - 153px);
                max-width:calc(100% - 153px);
            }
        }
    }
    .request-licenses-button{
        margin: 123px 0 0 0;
        padding-left: 50px;
        padding-bottom: 74px;

        .btn{
            height: 50px;
            width: 205px;
        }
    }
}
.licenses-modal {

    .modal{
        width:calc(100% - 238px);
        left: 238px;
        background-color:transparent;

        .modal-dialog{

            max-width: 485px;

            .modal-content{
                background-color: #F8F8F8;
                border-radius: 0;

                .modal-body{
                    padding:40px 35px;

                    h4{
                        font-size: 1.5rem;
                        line-height: 2.2rem;
                        font-weight: bold;
                        margin: 0;
                        text-align: center;
                    }
                    p{
                        font-size: 1.5rem;
                        line-height: 2.2rem;
                        font-weight: normal;
                        margin: 0;
                        text-align: center;
                    }
                     
                     
                }
                .modal-footer{
                    padding:0 35px 35px 35px;
                    border-top:none;
                    justify-content:center;

                    .btn{
                        margin: 0;
                        width: 200px;
                        height: 50px;
                        border-radius:2px;

                        &:not(:first-child):last-child{
                            margin-left: auto;
                        }
                    }
                }
            }
        }
    }
    .modal-backdrop{
        background-color:rgba(0,0,0,0.77) !important;
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter:blur(10px);
        opacity: 1 !important;
        width:calc(100% - 238px);
        left: 238px;
        display: block;
        top: 0;
    }
}

@media screen and (max-width:1670px){
    .licenses-card{

        .card-box{
    
            .card__header{
                 
                .help__inner__search{
                    flex:1;
                }
                 
            }
        }
    }
        
}
@media screen and (max-width:1440px){
    .licenses-card{

        .card-box{
    
            .card__header{

                .d-flex.col{
                    flex-wrap: wrap;
                    .help__inner__search{
                        flex:0 0 100%;
                        max-width: 100%;
                        margin: 0 0 10px 0;
                    }
                    .btn-filter{
                        margin-left: auto;
                    }
                    .card__pagination{
                        margin-right: 0px;
                    }
                }
                &>.row.align-items-center{
                    align-items:flex-start !important;
                }
            }
        }
    }
}
@media screen and (max-width:1199px){
    .licenses-card{

        .card-box{
    
            .card__header{

                .card__title{
                    flex:0 0 100%;
                    max-width: 100%;
                    margin-bottom: 16px;
                }
                .d-flex.col {
                    flex:0 0 100%;
                    max-width: 100%;
                }

                .filter__body{
                    .custom-form{
                        .form-group{
                            width: 25%;
                        }
                    }
                }
            }
        }
    }
    
}

@media screen and (max-width:1024px){
    .licenses-card{

        .card-box{
    
            .card__header{

                .filter__body{
                    .custom-form{
                        .form-group{
                            width: 50%;
                            margin-top: 10px;
                        }
                    }
                }
                 
            }
        }
    }
    
}