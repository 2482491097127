// File: theme.scss
// Override default BT variables:
$grid-columns:      12;
$grid-gutter-width: 26px;

$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: 576px,
  // Medium screen / tablet
  md: 768px,
  // Large screen / desktop
  lg: 1200px,
  // Extra large screen / wide desktop
  xl: 1920px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
);

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 2.6)
  ),
  $spacers
);

$brand-color:#FFA400;
$primary-color:#357BCF;
$secondary-color:#6c757d;

$link-color:#357BCF;
$light-grey-color:#68737c;


$btn-primary:#357BCF;
$btn-primary-border:#126BD6;

$btn-gray-color:#CED1D7;

$btn-red:#BA4640;
$btn-red-border:#BA4640;

$btn-danger:#DD4132;
$btn-danger-border:#DD4132;

$btn-warning:#F0B532;
$btn-warning-text-color:#1C2852;

$btn-success:#79C753;

$dark-color : #343434;  
$title-color : #343434;
$grey-color : #434343;
$white-color:#fff;
$black-color:#000;
$bg-color: #edeff1;
$color-gray:#757575;
$bg-light-red:#F0B532;

$color-danger:#BA4640;
$color-warning:#FFA400;
$color-success: #59A600;


$main-font-color:#343434;
$menu-link-color:#b1b1b1;
$border-color:#d3d3d3;

$link-hover:darken($brand-color,10%);


$body-font :  'SFProText-Medium', sans-serif;
$title-font:  'SFProDisplay-Bold', sans-serif;
$SFProSemibold:  'SFProText-Semibold', sans-serif;
$SFProMedium: 'SFProText-Medium', sans-serif;
$SFProTextBold: 'SFProText-Bold', sans-serif;
$SFProTextRegular: 'SFProText-Regular', sans-serif;

$btn-default:#272727;
$btn-default-color:#FFFFFF;
$btn-default-border:#272727;

$btn-primary:#F25555; 
$btn-primary-border:#F25555;
$btn-primary-color:#FFFFFF;

$btn-default:#272727;
$btn-default-color:#FFFFFF;
$btn-default-border:#272727;



$btn-gray-color:#E0E0E0;

$btn-red:#FF3F34;
$btn-red-border:#E13D34;

$btn-danger:#DD4132;
$btn-danger-border:#DD4132;

$btn-warning:#F0B532;
$btn-warning-text-color:#1C2852;

$btn-success:#79C753;

// $dark-color : #272727;  
// $title-color : #424242;
$grey-color : #434343;
$white-color:#fff;
$black-color:#000;
$bg-color: #edeff1;
$color-gray:#757575;
$bg-light-red:#F0B532;

$color-danger:#BA4640;
$color-warning:#FFA400;
$color-success: #59A600;


// $main-font-color:#424242;
$menu-link-color:#357BCF;
$border-color:#d3d3d3;

$link-hover:darken($brand-color,10%);


$body-font :  'Roboto', sans-serif;
$title-font:  'Roboto', sans-serif;
$SFProSemibold: 'Roboto', sans-serif;
$SFProMedium:  'Roboto', sans-serif;
$SFProTextBold: 'Roboto', sans-serif;
$SFProTextRegular: 'Roboto', sans-serif;